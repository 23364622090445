.lk-custom {
  &.control-bar-btn {
    padding-left: 1.25rem;
    padding-right: 0.75rem;
    background-color: transparent;
    &:hover {
      background: transparent;
    }
  }
  &.control-bar-icon {
    padding: 0px 2px 0px 0px;
    position: absolute;
    top: 5px;
    right: 0px;
    background: transparent;
  }
  &.lk-btn-enrolnow {
    border-radius: 10px;
    background: color(#467cf1);
  }
}
